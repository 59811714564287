import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  useEffect(
    () =>{
      if(window.location.href.toLowerCase().includes("gpro")){
        window.location = "/game-programmer/resume";
      }else if(window.location.href.toLowerCase().includes("soft")){
        window.location = "/software-engineer/resume";
      }
    },[]
  )
  return (
  <div style={{margin: "10px"}}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)}

export default NotFoundPage
